// Copyright © 2023 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { useEffect, useState } from 'react'

const useDebounce = (value, delay = 350, sideEffects) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    // Unless it's the first render, set the debounced value to the current value.
    if (debouncedValue === value) {
      return
    }
    const timer = setTimeout(() => {
      setDebouncedValue(value)
      if (sideEffects) {
        sideEffects()
      }
    }, delay)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay, sideEffects, debouncedValue])

  return debouncedValue
}

export default useDebounce
